import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import ProgramTemplateItemX from '@uz/unitz-tool-components/ProgramTemplateItemX';
import Paginator from '@uz/unitz-components-web/Paginator';
import { Form } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import ToolSearchInput from '@uz/unitz-tool-components/ToolSearchInput';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const ProgramHeader = () => {
  return (
    <DIV>
      <div className="flex w-full justify-between items-center py-4">
        <div className="text-main uppercase font-semibold text-base">
          {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.title')}
        </div>
        <Row justify="end">
          <Col>
            <Link to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplateCreate')}>
              <Button type="primary" size="medium">
                <div className="flex items-center">
                  <div className="text-white500 font-semibold">
                    {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Action.add_program_template')}
                  </div>
                  <div>
                    {gstyles.icons({
                      name: 'plus',
                      size: 14,
                      fill: gstyles.colors.white500,
                      className: 'mx-2',
                    })}
                  </div>
                </div>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <div className="template-list">
            <FormProvider form={ctx.get('filterForm')}>
              <Form {...layout} className="CourseTemplateFilterMenu">
                <div className="bg-white500 rounded-lg p-6">
                  <Row>
                    <Col span={6}>
                      <ToolSearchInput
                        name="search"
                        label={ctx.apply('i18n.t', 'ZoomToolMember.Form.search_label')}
                        onClick={ctx.get('filterForm.handleSubmit')}
                      />
                    </Col>
                  </Row>
                </div>
                <ProgramHeader />
                {renderPropsComposer(
                  {
                    matcher: (props) =>
                      !_.get(props, 'items.length') &&
                      !_.get(props, 'isLoading') &&
                      !ctx.apply('paginationModel.hasFilterArgs'),
                    render: () => (
                      <Row justify="center" className="py-16">
                        <Col span={10}>
                          <div className="flex flex-col items-center justify-center">
                            <div className="mb-4">
                              {gstyles.icons({
                                name: 'courseEmpty',
                                size: 152,
                              })}
                            </div>
                            <div className="text-center text-2xl font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.empty_text1')}
                            </div>
                            <div className="my-8 text-2xl text-center font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.empty_text2')}
                            </div>
                            <div className="text-2xl text-center font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.empty_text3')}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
                    render: () => (
                      <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
                        <LoadingScreen />
                      </div>
                    ),
                  },
                  {
                    matcher: (props) => !!_.get(props, 'items.length') || ctx.apply('paginationModel.hasFilterArgs'),
                    render: () => (
                      <>
                        <Row gutter={[0, 16]} justify="center">
                          {_.map(ctx.get('paginationModel.items'), (item, index) => (
                            <React.Fragment key={index}>
                              <DIV>
                                {ctx.debug(() => {
                                  ctx.set('@item', item);
                                })}
                                <ProgramTemplateItemX />
                              </DIV>
                            </React.Fragment>
                          ))}
                        </Row>
                        <Paginator />
                      </>
                    ),
                  },
                  () => null
                )(ctx.get('paginationModel'))}
              </Form>
            </FormProvider>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
